import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import ExitToIcon from '@material-ui/icons/ExitToApp'

const useStyles = makeStyles(theme => ({
    root: {

    },
    button: {
        borderColor: "#48bd96",
        color: "#48bd96",
        '&:hover': {
            backgroundColor: "#ffe066",
            color: "#000000",
        },
    }
}));

const ReservationLink = ({reservation}) => {
    const classes = useStyles();
    return (
        <Button href={reservation.url} className={classes.button} variant="outlined">
            {reservation.title} <ExitToIcon />
        </Button>
    );
};

ReservationLink.propTypes = {
    reservation: PropTypes.object,
}

export default ReservationLink