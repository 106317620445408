import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import ExitToIcon from '@material-ui/icons/ExitToApp'

import ReservationLink from './reservationlink'

const useStyles = makeStyles(theme => ({
    root: {

    },
    table: {
        margin: '0 30px',
        width: '85%',
    },
    heading2: {
        // color: '#48bd98',
        marginTop: '5rem',
        '&::before': {
            content: '""',
            display: 'inline-block',
            position: 'relative',
            top: '-.1em',
            verticalAlign: 'middle',
            height: '1em',
            borderLeftColor: '#48bd98',
            borderLeftWidth: '7px',
            borderLeftStyle: 'solid',
            marginRight: '10px',
        }
    },
    heading3: {
        paddingLeft: '15px',
    },
    reservationContainer: {
        '& > *': {
            marginRight: theme.spacing(1),
        }
    },
    button: {
        borderColor: "#48bd96",
        color: "#48bd96",
        '&:hover': {
            backgroundColor: "#ffe066",
            color: "#000000",
        },
    }
}));

const units = {
    ja: {
        second: "秒",
        minute: "分",
        hour: "時間",
        walk: "徒歩",
        car: "車で",
        train: "電車で",
        bicycle: "自転車で"
    },
    en: {
        second: "second",
        minute: "minite",
        hour: "hour",
        walk: "walk",
        car: "drive",
        train: "電車で",
        bicycle: "自転車で"
    },
    zh_hant: {
        second: "秒",
        minute: "分鐘",
        hour: "小時",
        walk: "徒歩",
        car: "車で",
        train: "電車で",
        bicycle: "自転車で"
    },
    zh_hans: {
        second: "秒",
        minute: "分钟",
        hour: "小时",
        walk: "徒歩",
        car: "車で",
        train: "電車で",
        bicycle: "自転車で"
    },

};

const tableHeaderContents = {
    ja: {
        address: "住所",
        phone: "電話番号",
        access: "駅からの移動法",
        pickup: "送迎",
        reservation: "予約",
        website: "Webサイト",
        creditcard: "クレジットカード",
        map: "地図"
    },
    en: {
        address: "Address",
        phone: "Telephone",
        access: "Access from the Station",
        pickup: "Shuttle Service",
        reservation: "Reservation",
        website: "Website",
        creditcard: "Credit Card",
        map: "MAP"
        
    },
    zh_hant: {
        address: "地址",
        phone: "電話號碼",
        access: "如何從車站前往",
        pickup: "接送服務",
        reservation: "訂房",
        website: "網站",
        creditcard: "信用卡卡",
        map: "地圖"
    },
    zh_hans: {
        address: "地址",
        phone: "电话号码",
        access: "如何从车站前往",
        pickup: "接送服务",
        reservation: "订房",
        website: "网站",
        creditcard: "信用卡卡",
        map: "地图"
    }
}

const HotelInfo = ({language, hotel, page}) => {
    const classes = useStyles();
    
    let address;
    if (page.address.prefecture && page.address.city && page.address.address1) {
        let addTmp;
        if ('en' === page.language) {
            addTmp = page.address.address1+', '+page.address.city+', '+page.address.prefecture;
        } else {
            addTmp = page.address.prefecture+page.address.city+page.address.address1+page.address.address2;
        }
        address = <TableRow>
                    <TableCell component="th" scope="row">{tableHeaderContents[language].address}</TableCell>
                    <TableCell>{addTmp}</TableCell>
                </TableRow>;
    } else {
        address = "";
    }
    let telephone;
    if (hotel.phoneNumber) {
        telephone = <TableRow>
                        <TableCell component="th" scope="row">{tableHeaderContents[language].phone}</TableCell>
                        <TableCell>{hotel.phoneNumber}</TableCell>
                    </TableRow>;
    } else {
        telephone = "";
    }
    let access;
    if (page.access) {
        access = <TableRow>
                    <TableCell component="th" scope="row">{tableHeaderContents[language].access}</TableCell>
                    <TableCell>{page.access}</TableCell>
                </TableRow>;
    } else {
        access = "";
    }
    let pickup;
    if (page.pickup) {
        pickup = <TableRow>
                    <TableCell component="th" scope="row">{tableHeaderContents[language].pickup}</TableCell>
                    <TableCell>{page.pickup}</TableCell>
                </TableRow>;
    } else {
        pickup = "";
    }
    let website;
    if (hotel.website) {
        website = <TableRow>
                    <TableCell component="th" scope="row">{tableHeaderContents[language].website}</TableCell>
                    <TableCell><Button variant="outlined" className={classes.button} href={'http://'+hotel.website}>{page.hotelName} <ExitToIcon /></Button></TableCell>
                </TableRow>;
    } else {
        website = "";
    }
    const creditcards = hotel.rakuten.hotelPolicyInfo.availableCreditCard.map(item => item.card).join(', ');
    let creditCard;
    if (creditcards) {
        creditCard = <TableRow>
                        <TableCell component="th" scope="row">{tableHeaderContents[language].creditcard}</TableCell>
                        <TableCell>{creditcards}</TableCell>
                    </TableRow>;
    } else {
        creditCard = "";
    }
    
    let reservation;
    const reserveTmp = page.reservations.map(reservation => <ReservationLink reservation={reservation} />);
    if (page.reservations[0].url) {
        if ('ja' == page.language) {
            reservation = <TableRow>
                            <TableCell component="th" scope="row">{tableHeaderContents[language].reservation}</TableCell>
                            <TableCell className={classes.reservationContainer}>{reserveTmp}</TableCell>
                        </TableRow>;
        } else {
        reservation = "";            
        }
    } else {
        reservation = "";
    }
    

    return (
        <section>
            <h2 className={classes.heading2}>{page.hotelName}</h2>
            <TableContainer>
                <Table className={classes.table} aria-label="Hotel Information">
                    <TableBody>
                        {address}
                        {telephone}
                        {access}
                        {pickup}
                        {creditCard}
                        {website}
                        {reservation}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* <pre>{JSON.stringify(hotel.rakuten.hotelPolicyInfo.availableCreditCard, null, 4)}</pre> */}
        </section>
    )
}

HotelInfo.propTypes = {
    language: PropTypes.string,
    hotel: PropTypes.object,
    page: PropTypes.object,
}

export default HotelInfo